import React, { useState } from "react";
import logo from "../assets/updatedimage.png";
import { Typography, IconButton, useMediaQuery } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import { Link } from "react-router-dom";

const Navbar = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const isSmallScreen = useMediaQuery("(max-width:960px)");

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const scrollToSection = (id) => {
  
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="w-full bg-[#EEEEEE] fixed top-0  right-0 z-50">
      <div className="flex justify-between items-center w-full py-2 h-24  px-[180px]">
        <div className="w-1/2">
          <img src={logo} alt="Logo" className="w-28 h-15 rounded-full" />
        </div>
        {isSmallScreen ? (
          <>
            <IconButton
              className="lg:hidden"
              onClick={toggleDrawer}
              aria-label="menu"
            >
              <MenuIcon />
            </IconButton>
            {isDrawerOpen && (
              <div className="fixed top-0 right-0 w-2/3 h-full bg-white shadow-md z-50">
                <IconButton
                  onClick={toggleDrawer}
                  aria-label="close"
                  className="absolute top-4 right-4"
                >
                  <CloseIcon />
                </IconButton>
                <div className="flex flex-col pl-6 pt-20 gap-6">
                  <Typography
                    className="cursor-pointer font-poppins text-[#006198]"
                    variant="body1"
                    onClick={() => scrollToSection("home")}
                  >
                    <Link to="/">Home</Link>
                  </Typography>

                  <Typography
                    className="cursor-pointer font-bold text-[#006198]"
                    variant="body1"
                    onClick={() => scrollToSection("events")}
                  >
                    <Link to="/">Events</Link>
                  </Typography>
                  <Typography
                    className="cursor-pointer font-poppins text-[#006198]"
                    variant="body1"
                    onClick={() => scrollToSection("home")}
                  >
                    <Link to="/Academic">Academic</Link>
                  </Typography>
                  <Typography
                    className="cursor-pointer font-bold text-[#006198]"
                    variant="body1"
                    onClick={() => scrollToSection("home")}
                  >
                    <Link to="/about-us">About Us</Link>
                  </Typography>
                </div>
              </div>
            )}
          </>
        ) : (
          <div className="hidden md:flex flex-row w-full justify-end px-8 gap-10 text-sm ">
            <Typography
              className="cursor-pointer font-poppins text-[#006198]"
              variant="body1"
              onClick={() => scrollToSection("home")}
            >
              <Link to="/">Home</Link>
            </Typography>

            <Typography
              className="cursor-pointer font-bold text-[#006198]"
              variant="body1"
              onClick={() => scrollToSection("events")}
            >
              <Link to="/">Events</Link>
            </Typography>
            <Typography
              className="cursor-pointer font-poppins text-[#006198] flex items-center"
              variant="body1"
              onClick={() => scrollToSection("home")}
            >
              <Link to="/academic">
                Academic
              </Link>
            </Typography>
            <Typography
              className="cursor-pointer font-bold text-[#006198]"
              variant="body1"
              onClick={() => scrollToSection("about")}
            >
              <Link to="/about-us">
                About<span className="ml-1">Us</span>
              </Link>
            </Typography>
          </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;
